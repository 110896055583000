import React from 'react';
import { Link } from 'gatsby';
import Rocket from './../assets/rocket.svg';

const NotFoundPage = () => {
  return (
    <div className="h-screen w-screen bg-gray-100 flex items-center">
      <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
        <div className="max-w-md">
          <div className="text-titleXxlMobile sm:text-titleXxlTablet lg:text-titleXxl font-universBoldCondensed">
            404
          </div>
          <p className="text-lg leading-normal">Leider konnten wir diese Seite nicht finden.</p>
          <p className="mb-8 mt-2">Aber keine Sorge, auf unserer Homepage finden Sie noch viele andere Dinge.</p>
          <Link
            to={'/'}
            className="inline-block overflow-hidden py-3 px-6 leading-none font-universBoldCondensed text-center rounded shadow focus:shadow-outline transition-colors duration-150 text-white bg-blue-aqua hover:bg-blue-navy"
          >
            Zurück zur Homepage
          </Link>
        </div>
        <div className="w-full mt-8 md:w-1/2 md:mt-0 text-center">
          <img src={Rocket} className="w-44 h-44 md:w-52 md:h-52 lg:w-64 lg:h-64" />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
